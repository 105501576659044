@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&family=Lora:wght@400;500;600;700&family=Nunito:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&family=Roboto+Condensed:wght@400;700&family=Roboto:wght@700&family=Source+Sans+3:wght@300;400;500;600;700&family=Urbanist:wght@400;600;700&family=Varela&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* cursor: none; */
}
.count{
  background-image: url('./assets/countdown.png');
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #ee2a7b;
  border-radius: 2px;
}

.text {
  font-size: 6em;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
    1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
    1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
    1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

/* .register_btn css */
.register_btn {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 17px;
  padding: 0.8em 1.3em 0.8em 0.9em;
  color: white;
  background: #ad5389;
  background: linear-gradient(to right, #ce3374, #ee2a7b);
  border: none;
  letter-spacing: 0.05em;
  border-radius: 16px;
}

.register_btn svg {
  margin-right: 3px;
  transform: rotate(30deg);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.register_btn span {
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.register_btn:hover svg {
  transform: translateX(5px) rotate(90deg);
}

.register_btn:hover span {
  transform: translateX(7px);
}

.fold-btn {
  position: relative;
  background-color: #ee2a7b;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
  border-radius: 0.5em;
  color: #dedede;
  box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.3);
}

.fold-btn::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  background: linear-gradient(
    135deg,
    rgba(33, 33, 33, 1) 0%,
    rgba(33, 33, 33, 1) 50%,
    rgba(150, 4, 31, 1) 50%,
    rgba(191, 4, 38, 1) 60%
  );
  border-radius: 0 0 0.5em 0;
  box-shadow: 0.2em 0.2em 0.2em rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.fold-btn:hover::before {
  width: 1.6em;
  height: 1.6em;
}

.fold-btn:active {
  box-shadow: 0.2em 0.2em 0.3em rgba(0, 0, 0, 0.3);
  transform: translate(0.1em, 0.1em);
}

.insta {
  background: -webkit-linear-gradient(#944eb9, #c64a92);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.arrowfaq {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
}
@media (max-width: 1024px) {
  .text {
    font-size: 4em;
  }
}

.loader {
  position: relative;
  font-size: 16px;
  width: 5.5em;
  height: 5.5em;
}

.loader:before {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 50%;
}

.loader:after {
  content: "";
  position: absolute;
  left: 0.2em;
  bottom: 0.18em;
  width: 1em;
  height: 1em;
  background-color: orange;
  border-radius: 15%;
  animation: rollingRock 2.5s cubic-bezier(0.79, 0, 0.47, 0.97) infinite;
}

@keyframes rollingRock {
  0% {
    transform: translate(0, -1em) rotate(-45deg);
  }

  5% {
    transform: translate(0, -1em) rotate(-50deg);
  }

  20% {
    transform: translate(1em, -2em) rotate(47deg);
  }

  25% {
    transform: translate(1em, -2em) rotate(45deg);
  }

  30% {
    transform: translate(1em, -2em) rotate(40deg);
  }

  45% {
    transform: translate(2em, -3em) rotate(137deg);
  }

  50% {
    transform: translate(2em, -3em) rotate(135deg);
  }

  55% {
    transform: translate(2em, -3em) rotate(130deg);
  }

  70% {
    transform: translate(3em, -4em) rotate(217deg);
  }

  75% {
    transform: translate(3em, -4em) rotate(220deg);
  }

  100% {
    transform: translate(0, -1em) rotate(-225deg);
  }
}
